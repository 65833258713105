<template>
    <div class="col-4">
        <p class="f-600 f-16 text-center">
            Solicitud de afiliación enviada.
        </p>
        <p class="f-400 my-3 f-16 text-center">
            Pronto tendrás los beneficios de {{ convenio.nombre }}
        </p>
        <div class="btn-general mx-5 mb-3" @click="accion = 'irTienda', ejecutarAccion()">
            Ir a la tienda
        </div>
        <div class="btn-outline mx-5" @click="accion = 'irEliminar', confirmarCancelar()">
            Cancelar solicitud
        </div>
        <!-- Partials -->
        <modal ref="modalCancelar" titulo="Cancelar solicitud" cancelar="Cerrar" adicional="Cancelar" @adicional="ejecutarAccion">
            <p class="f-400 mx-5 text-center">
                ¿Estás seguro de cancelar la solicitud? <br /> Puedes enviarla en otra oportunidad
            </p>
        </modal>
    </div>
</template>

<script>
export default {
    props:{
        convenio: {
            type: Object,
            default:() => {},
        },
    },
    data(){
        return {
            accion: '',
        }
    },
    methods: {
        ejecutarAccion(){
            this.$emit('accion', this.accion);
            if(this.accion === 'irEliminar'){
                this.$refs.modalCancelar.toggle();
            }
        },
        confirmarCancelar(){
            this.$refs.modalCancelar.toggle();
        }
    }
}
</script>
